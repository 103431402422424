import {
  GET_PATIENTS,
  GET_PATIENT_DETAILS,
  CREATE_PATIENT,
  GET_PATIENT_EXAMS,
  IMAGES_SUBMITTED,
  UPDATE_PATIENT_CONTACT_DETAILS,
  UPDATE_PATIENT_DEMOGRAPHIC_DETAILS,
  UPDATE_PATIENT_EXAMS,
  UPDATE_EXAM_STATUS,
  GET_PATIENT_RISK_SERVICES,
} from '../actions/types.js';
import {
  EXAM_PENDING,
  EXAM_SUBMITTED,
  IMAGES_STORED,
  PENDING_ACTION,
  SERVICE_NOT_PURCHASED,
} from '../constants/constants.js';
import { createReducer } from '@reduxjs/toolkit';

const initialState = {
  patients: {},
  patientDetails: {},
  exam_details: {},
};

const patientsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(GET_PATIENTS, (state, action) => {
      state.patients = action.payload;
    })
    .addCase(GET_PATIENT_DETAILS, (state, action) => {
      state.patientDetails = {
        ...state.patientDetails,
        ...action.payload,
      };
    })
    .addCase(CREATE_PATIENT, (state, action) => {
      state.patients = action.payload;
    })
    .addCase(GET_PATIENT_EXAMS, (state, action) => {
      let exams = Array.isArray(action.payload) ? [...action.payload] : [];
      if (exams[0]?.process?.status === EXAM_PENDING && exams[0].process.overall_status !== IMAGES_STORED) {
        exams[0].process.overall_status = PENDING_ACTION;
      }
      state.patientDetails = {
        ...state.patientDetails,
        exams: exams,
      };
    })
    .addCase(GET_PATIENT_RISK_SERVICES, (state, action) => {
      // Reducer for storing the risk prediction services for a patient
      state.patientDetails = {
        ...state.patientDetails,
        risk_services: action.payload,
      };
    })
    .addCase(IMAGES_SUBMITTED, (state, action) => {
      if (!state.patientDetails) return { ...state };
      let exams = [...(state.patientDetails.exams || [])];
      if (exams && Array.isArray(exams))
        exams.forEach((exam, index) => {
          if (exam.id === action.payload.exam_id) {
            exams[index].process.status = EXAM_SUBMITTED;
          }
        });
      state.patientDetails = { ...state.patientDetails, exams };
    })
    .addCase('UPDATE_PATIENT_EXAMS', (state, action) => {
      let newPatientDetailsExams = [...state.patientDetails.exams];
      newPatientDetailsExams.splice(0, 0, action.payload);
      state.patientDetails = { ...state.patientDetails, exams: newPatientDetailsExams };
    })
    .addCase('UPDATE_EXAM_STATUS', (state, action) => {
      if (!state.patientDetails) return { ...state };
      let newPatientDetailsExams2 = [...state.patientDetails.exams];
      for (let i = 0; i < newPatientDetailsExams2.length; i++) {
        if (newPatientDetailsExams2[i].id === action.payload['id']) {
          let newEntry = {
            ...newPatientDetailsExams2[i],
            process: {
              ...newPatientDetailsExams2[i].process,
              status: action.payload['status'],
            },
          };
          newPatientDetailsExams2[i] = newEntry;
        }
      }
      state.patientDetails = { ...state.patientDetails, exams: newPatientDetailsExams2 };
    })
    // .addMatcher(
    // 	(action) =>
    // 		action.type === UPDATE_PATIENT_CONTACT_DETAILS || action.type === UPDATE_PATIENT_DEMOGRAPHIC_DETAILS,
    // 	(state, action) => {
    // 		let newPatientDetails = { ...state.patientDetails, ...action.payload };
    // 		state.patientDetails = newPatientDetails;
    // 	}
    // )
    .addDefaultCase((state, action) => {
      return state;
    });
});

export default patientsReducer;
