import { useState, useEffect, cloneElement } from 'react';
import { useHistory } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import FadeIn from 'react-fade-in';
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';

import { styled } from '@mui/material/styles';
import {
  Grid,
  Box,
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  FormControlLabel,
  Checkbox,
  DialogActions,
  Button,
} from '@mui/material';
import { Warning } from '@mui/icons-material';
import PropTypes, { any } from 'prop-types';
import { set } from 'date-fns';

import theme from '../../../styles/theme';
import SmallButton from '../../../components/layout/atoms/SmallButton';
import LargeButton from '../../../components/layout/atoms/LargeButton';
import InfoToolTip from '../../../components/layout/atoms/InfoToolTip';
import ResultsToolTip from '../../../components/layout/molecules/ResultsToolTip';
import ExamsAccordion from '../../../components/layout/molecules/ExamsAccordion';
import IconHeading from '../../../components/layout/molecules/IconHeading';
import Loading from '../../../components/layout/organisms/Loading/Loading';
import Modal from '../../../components/layout/templates/Modal';

import { createExam, deleteExambyid } from '../../../actions/exams';
import { getPatientExams } from '../../../actions/patients';
import { getBillingDetails } from '../../../actions/subscriptions';
import { setSnackbar, handleModalOpen, collapseNavBarLeft } from '../../../actions/controls';
import { transferValueGetKey } from '../../../utils/helpers';
import {
  getDeployRegion,
  getProfileLayoutByRegion,
  getRegionSpecificExamNames,
} from '../../../utils/configurationHelpers';
import { convertToCustomDateString, convertToCustomDateStringforDelete } from '../../../utils/date';
import {
  EXAM_ORDERED,
  EXAM_PENDING,
  EXAM_SUBMITTED,
  IMAGES_STORED,
  REPORT_READY,
  GERMANY,
} from '../../../constants/constants';
import {
  AIReviewGreyIcon,
  StoreImageGreyIcon,
  PlusIcon,
  TrashIcon,
  UserCheckIcon,
  XIcon,
} from '../../../resources/icons';

const StyledExamsGrid = styled(Grid)(({ theme }) => ({
  width: '100%',
  height: getProfileLayoutByRegion()?.examsHeight,
  padding: '0 3px',
  overflowY: 'auto',
}));

const StyledIconButton = styled(IconButton)(({ theme, showdelete = false }) => ({
  backgroundColor: showdelete ? theme.palette.error.light : theme.palette.background.dark,
  width: '44px',
  height: '40px',
  borderRadius: '10px',
  color: showdelete ? theme.palette.error.lighter : theme.palette.primary.main,
  '&:hover': {
    color: showdelete ? theme.palette.error.lighter : theme.palette.primary.main,
    backgroundColor: showdelete ? theme.palette.error.light : theme.palette.background.dark,
  },
  '&:disabled': {
    color: theme.palette.error.lighter,
    backgroundColor: theme.palette.error.light,
    opacity: 0.5,
  },
}));

const StyledButtonGrid = styled(Grid)(({ theme }) => ({
  justifyContent: 'flex-start',
  [theme.breakpoints.up('lg')]: {
    justifyContent: 'flex-end',
  },
}));

const ExamTitles = (props) => {
  const { lanStyle, staticWords } = props;

  return (
    <Grid item container display="flex" justifyContent="space-between" alignItems="center" px={3} xs={12}>
      <Grid
        item
        container
        xs={lanStyle.component_PatientExams.title_1.width_lg}
        xl={lanStyle.component_PatientExams.title_1.width_xl}
      >
        <Grid item xs={1.5}></Grid>
        <Grid
          item
          xs={lanStyle.component_PatientExams.label_1.width_lg}
          xl={lanStyle.component_PatientExams.label_1.width_xl}
        >
          <Typography textOverflow="ellipsis" overflow="hidden">
            {staticWords.Date}
          </Typography>
        </Grid>
        <Grid
          item
          xs={lanStyle.component_PatientExams.label_2.width_lg}
          xl={lanStyle.component_PatientExams.label_2.width_xl}
        >
          <Typography>{staticWords.Practitioner}</Typography>
        </Grid>
      </Grid>
      <Grid
        item
        display="flex"
        alignItems="center"
        gap={1}
        xs={lanStyle.component_PatientExams.title_2.width_lg}
        xl={lanStyle.component_PatientExams.title_2.width_xl}
      >
        <Grid mt={0.5}>
          <ResultsToolTip />
        </Grid>
        <Box component="div">
          <Typography>{staticWords.Status}</Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

const PREFIX = 'DeleteExamModal';

const classes = {
  container: `${PREFIX}-container`,
  form: `${PREFIX}-form`,
  formFields: `${PREFIX}-formFields`,
  submit: `${PREFIX}-submit`,
  datePicker: `${PREFIX}-datePicker`,
  switch: `${PREFIX}-switch`,
};

const StyledModal = styled(Modal)(({ theme }) => ({
  [`& .${classes.container}`]: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },

  [`& .${classes.form}`]: {
    margin: theme.spacing(2),
  },

  [`& .${classes.formFields}`]: {
    padding: theme.spacing(2),
  },

  [`& .${classes.submit}`]: {
    width: '30%',
  },

  [`& .${classes.datePicker}`]: {
    margin: '0',
  },

  [`& .${classes.switch}`]: {
    display: 'flex',
    alignItems: 'center',
  },
}));

// FUNCTIONAL COMPONENT
const PatientExams = (props) => {
  const { t, i18n } = useTranslation();

  const { patientDetails, exams, createExam, deleteExambyid, getPatientExams, getBillingDetails, collapseNavBarLeft } =
    props;

  const history = useHistory();
  const [selectedExam, setSelectedExam] = useState(null);
  const [deleteExamVisible, setDeleteExamVisible] = useState(false);
  const { words: staticWords, styles: lanStyle } = useSelector((state) => state.handlingTranslation);
  const { promiseInProgress } = usePromiseTracker({ area: 'patient-exams-area' });
  const [deleteExam, setDeleteExam] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const styledAIReviewIcon = cloneElement(<AIReviewGreyIcon />, { style: { width: 28, height: 28 } });
  const styledStoreImageIcon = cloneElement(<StoreImageGreyIcon />, { style: { width: 28, height: 28 } });
  const {
    patientExamsTitle,
    createExamButtonText,
    deleteExamTitle,
    deleteExamSubtitle,
    deleteExamConfirm,
    deleteExamButton,
  } = getRegionSpecificExamNames();
  const { exams: patientExams } = patientDetails;
  const firstExamPendingAction =
    patientExams &&
    patientExams[0]?.process?.status === EXAM_PENDING &&
    patientExams[0]?.process?.overall_status !== IMAGES_STORED;
  const examLimitReached = patientExams?.length >= 50;

  const handleOpen = (exam) => {
    setDeleteExam(exam);
    setIsModalOpen(true);
  };
  const handleClose = () => {
    setDeleteExam(null);
    setIsModalOpen(false);
  };

  const handleDelete = (examis) => {
    deleteExambyid(examis)
      .then((result) => {
        getPatientExams(patientDetails.id);
        getBillingDetails();
        setDeleteExamVisible(false);
      })
      .catch((error) => {
        setDeleteExamVisible(false);
      });

    handleClose();
  };

  const handleNewExamClick = () => {
    createExam(patientDetails, history);
  };

  const handleExamClick = (event, exam) => {
    event.preventDefault();
    const { id } = exam;
    const { status, overall_status } = exam.process;

    if (status === EXAM_PENDING) {
      if (overall_status === IMAGES_STORED) history.push(`/patient/${patientDetails?.id}/image_storage/${id}/`);
      else history.push(`/patient/${patientDetails?.id}/create_exam/${id}/`);
    } else if (status === EXAM_SUBMITTED) {
      history.push(`/patient/${patientDetails?.id}/exam_details/${id}/`);
    }
  };

  useEffect(() => {
    getPatientExams(patientDetails.id);
  }, []);

  useEffect(() => {
    if (deleteExamVisible && patientExams?.length > 0) {
      setDeleteExamVisible(true);
    } else {
      setDeleteExamVisible(false);
    }
  }, [patientExams?.length]);

  const renderExamsList = () => {
    return (
      <FadeIn>
        <Grid container mt={3}>
          <ExamTitles lanStyle={lanStyle} staticWords={staticWords} />
          <Grid item xs={12}>
            {patientDetails.exams.map((exam) => {
              return (
                <ExamsAccordion
                  key={exam.id}
                  icon={
                    exam.process?.overall_status === IMAGES_STORED ? (
                      <StoreImageGreyIcon style={{ width: 28, height: 28 }} />
                    ) : (
                      <AIReviewGreyIcon style={{ width: 28, height: 28 }} />
                    )
                  }
                  status={exam.process?.overall_status}
                  operator={exam.operator}
                  dateString={convertToCustomDateString(exam.process?.last_submitted_at, 'dd/MM/yyyy')}
                  selected={selectedExam === exam.id}
                  onMouseDown={() => setSelectedExam(exam.id)}
                  onMouseLeave={() => setSelectedExam(null)}
                  onClick={(event) => handleExamClick(event, exam)}
                  expandable={false}
                />
              );
            })}
          </Grid>
        </Grid>
      </FadeIn>
    );
  };

  const renderDeleteExamsList = () => {
    return (
      <FadeIn>
        <Grid container mt={3}>
          <Grid item container xs={12}>
            <Grid item xs={10.5}>
              <ExamTitles lanStyle={lanStyle} staticWords={staticWords} />
            </Grid>
            {patientDetails.exams.map((exam) => {
              return (
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={10.5}>
                    <ExamsAccordion
                      disabled={true}
                      key={exam.id}
                      icon={
                        exam.process?.overall_status === IMAGES_STORED ? (
                          <StoreImageGreyIcon style={{ width: 28, height: 28 }} />
                        ) : (
                          <AIReviewGreyIcon style={{ width: 28, height: 28 }} />
                        )
                      }
                      status={exam.process?.overall_status}
                      operator={exam.operator}
                      dateString={convertToCustomDateString(exam.process?.last_submitted_at, 'dd/MM/yyyy')}
                      selected={selectedExam === exam.id}
                      onMouseDown={() => setSelectedExam(exam.id)}
                      onMouseLeave={() => setSelectedExam(null)}
                      onClick={(event) => {}}
                      expandable={false}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    {/* delete button is hidden if the report status = ready  ref TEL-1397 updated requirement */}
                    {exam.process?.overall_status === REPORT_READY ? (
                      <InfoToolTip placement="bottom" content={t('LB_email_with_report_ready_cannot_delete')}>
                        <StyledIconButton
                          showdelete={true}
                          disabled={exam.process?.overall_status === REPORT_READY}
                          aria-label="delete"
                          onClick={() => handleOpen(exam)}
                        >
                          <TrashIcon width={18} height={18} />
                        </StyledIconButton>
                      </InfoToolTip>
                    ) : (
                      <StyledIconButton showdelete={true} aria-label="delete" onClick={() => handleOpen(exam)}>
                        <TrashIcon width={18} height={18} />
                      </StyledIconButton>
                    )}
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </FadeIn>
    );
  };

  const renderCancelButton = () => {
    return (
      <SmallButton
        startIcon={<XIcon />}
        onClick={() => {
          setDeleteExamVisible(false);
        }}
      >
        {staticWords.Cancel}
      </SmallButton>
    );
  };

  const renderDeleteButton = () => {
    return (
      <StyledIconButton
        disableRipple
        aria-label="delete"
        onClick={() => {
          collapseNavBarLeft(true);
          setDeleteExamVisible(true);
        }}
      >
        <TrashIcon width={18} height={18} />
      </StyledIconButton>
    );
  };

  const renderAddExamButton = () => {
    if (patientDetails.exams) {
      return (
        <SmallButton
          onClick={handleNewExamClick}
          startIcon={<PlusIcon />}
          disabled={firstExamPendingAction || examLimitReached}
        >
          {staticWords[transferValueGetKey(createExamButtonText)]}
        </SmallButton>
      );
    }
  };
  {
    /* Dialog */
  }
  const DeleteDialogView = ({ isModalOpen, handleClose, handleDelete, exam }) => {
    const [isChecked, setIsChecked] = useState(false);

    const handleCheckboxChange = (event) => {
      setIsChecked(event.target.checked);
    };

    return (
      <Dialog
        open={isModalOpen}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
        sx={{
          justifyContent: 'center',
          padding: '20px',
        }}
      >
        <DialogTitle sx={{ marginLeft: theme.spacing(2), marginTop: theme.spacing(2) }}>
          <IconHeading
            title={deleteExamTitle}
            icon={<Warning />}
            style_2={{ backgroundColor: theme.palette.error.lighter }}
          />
        </DialogTitle>
        <DialogContent sx={{ marginLeft: 2 }}>
          <Typography sx={(theme.typography.h4, { color: theme.palette.text.grey })}>
            {t(deleteExamSubtitle)}
          </Typography>
          <Grid
            container
            sm={12}
            alignContent="center"
            padding={2}
            sx={{
              backgroundColor: theme.palette.background.dark,
              borderRadius: '10px',
              padding: '14px',
              marginTop: '16px',
            }}
          >
            <Grid container sm={12}>
              <Grid item sm={lanStyle.component_PatientExams.width_1}>
                <Typography
                  padding={theme.spacing(1)}
                  sx={
                    (theme.typography.h6, { fontSize: '16px', color: theme.palette.text.primary, fontWeight: 'bold' })
                  }
                >
                  {t('Date')}
                </Typography>
              </Grid>
              <Grid item sm={lanStyle.component_PatientExams.width_2}>
                <Typography
                  padding={theme.spacing(1)}
                  sx={(theme.typography.h6, { fontSize: '16px', fontWeight: 400, color: theme.palette.text.primary })}
                >
                  {convertToCustomDateStringforDelete(exam?.process?.last_submitted_at, 'dd-MM-yyyy, hh:mm:ss')}
                </Typography>
              </Grid>
            </Grid>
            <Grid container sm={12}>
              <Grid item sm={lanStyle.component_PatientExams.width_1}>
                <Typography
                  padding={theme.spacing(1)}
                  sx={
                    (theme.typography.h6, { fontSize: '16px', color: theme.palette.text.primary, fontWeight: 'bold' })
                  }
                >
                  {t('Patient')}
                </Typography>
              </Grid>
              <Grid item sm={lanStyle.component_PatientExams.width_2}>
                <Typography
                  padding={theme.spacing(1)}
                  sx={(theme.typography.h6, { fontSize: '16px', fontWeight: 400, color: theme.palette.text.primary })}
                >
                  {patientDetails?.user?.user_contact?.first_name || '-'}{' '}
                  {patientDetails?.user?.user_contact?.last_name || ''} ( {patientDetails?.account_number || '-'} )
                </Typography>
              </Grid>
            </Grid>
            <Grid container sm={12}>
              <Grid item sm={lanStyle.component_PatientExams.width_1}>
                <Typography
                  padding={theme.spacing(1)}
                  sx={
                    (theme.typography.h6, { fontSize: '16px', color: theme.palette.text.primary, fontWeight: 'bold' })
                  }
                >
                  {t('Practitioner')}
                </Typography>
              </Grid>
              <Grid item sm={lanStyle.component_PatientExams.width_2}>
                <Typography
                  padding={theme.spacing(1)}
                  textTransform="capitalize"
                  sx={(theme.typography.h6, { fontSize: '16px', fontWeight: 400, color: theme.palette.text.primary })}
                >
                  {exam?.operator?.first_name || '-'}
                  {exam?.operator?.branch && ` (${exam?.operator?.branch})`}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <FormControlLabel
            control={<Checkbox checked={isChecked} onChange={handleCheckboxChange} color="primary" />}
            label={t(deleteExamConfirm)}
            style={
              (theme.typography.h6,
              { marginTop: '14px', fontSize: '13px', fontWeight: 400, color: theme.palette.text.primary })
            }
          />
          <Grid
            container
            sm={12}
            spacing={2}
            alignContent={'center'}
            padding={2}
            sx={{
              borderRadius: '10px',
              padding: '0',
              marginTop: '16px',
            }}
          >
            <Grid container display="flex" justifyContent="space-between">
              <Grid item xs={6}>
                <LargeButton customWidth="27ch" buttonType="secondary" onClick={handleClose}>
                  {t('Cancel')}
                </LargeButton>
              </Grid>
              <Grid item xs={6}>
                <LargeButton
                  type="submit"
                  customWidth="27ch"
                  onClick={() => handleDelete(exam?.id)}
                  color="blue"
                  buttonType={'red'}
                  disabled={!isChecked}
                >
                  {t(deleteExamButton)}
                </LargeButton>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  };

  return (
    <Grid container>
      {/* SECTION TITLE */}
      <Grid container alignItems="center" justifyContent="space-between" mt={0.5} mb={2}>
        <Grid item px={1}>
          <IconHeading
            alternateTitle={
              deleteExamVisible
                ? `${t(transferValueGetKey(patientExamsTitle))} - ${t('Delete')}`
                : t(transferValueGetKey(patientExamsTitle))
            }
            icon={<UserCheckIcon />}
          />
        </Grid>
        <Grid item>
          {deleteExamVisible ? (
            renderCancelButton()
          ) : (
            <StyledButtonGrid container display="flex" item wrap="nowrap" spacing={1} alignItems="center">
              {patientExams?.length > 0 ? <Grid item>{renderDeleteButton()}</Grid> : null}
              <Grid item>
                {firstExamPendingAction || examLimitReached ? (
                  <InfoToolTip
                    placement="left"
                    title={
                      getDeployRegion() === GERMANY
                        ? staticWords.Unable_to_add_new_record
                        : staticWords.Unable_to_add_new_exam
                    }
                    content={
                      examLimitReached
                        ? getDeployRegion() === GERMANY
                          ? staticWords.Each_patient_can_only_have_a_maximum_of_50_records_
                          : staticWords.Each_patient_can_only_have_a_maximum_of_50_exams_
                        : getDeployRegion() === GERMANY
                          ? staticWords.Please_complete_and_submit_the_most_recent_record_to_add_new_record_
                          : staticWords.Please_complete_and_submit_the_most_recent_exam_to_add_new_exam_
                    }
                  >
                    <div>{renderAddExamButton()}</div>
                  </InfoToolTip>
                ) : (
                  renderAddExamButton()
                )}
              </Grid>
            </StyledButtonGrid>
          )}
        </Grid>

        {/* EXAM LISTS */}
        <StyledExamsGrid>
          {promiseInProgress ? (
            <Grid mt={12}>
              <Loading area="patient-exams-area" />
            </Grid>
          ) : patientExams === undefined ? null : patientExams?.length ? (
            deleteExamVisible ? (
              renderDeleteExamsList()
            ) : (
              renderExamsList()
            )
          ) : (
            <Typography variant="subtitle1" align="center" mt={12}>
              {staticWords.This_patient_does_not_have_any_existing_exams_}
            </Typography>
          )}
        </StyledExamsGrid>
        <DeleteDialogView
          isModalOpen={isModalOpen}
          handleClose={handleClose}
          handleDelete={handleDelete}
          exam={deleteExam} // Pass selected exam
        />
      </Grid>
    </Grid>
  );
};

PatientExams.propTypes = {
  patientDetails: PropTypes.object,
  exams: PropTypes.object,
  createExam: PropTypes.func,
  getPatientExams: PropTypes.func,
};

// MAP REDUX STATE TO COMPONENT PROPS
// To retrieve patients from props, use -> props.patients
const mapStateToProps = (state) => ({
  patientDetails: state.patients.patientDetails,
  exams: state.exams,
});

export default connect(mapStateToProps, {
  createExam,
  deleteExambyid,
  setSnackbar,
  getPatientExams,
  getBillingDetails,
  collapseNavBarLeft,
})(PatientExams);
