import ReviewsAccordion from '../components/layout/molecules/ReviewsAccordion';
import { AUSTRALIA, GERMANY, INDIA, IRELAND, SINGAPORE, UNITED_KINGDOM } from '../constants/constants';
import theme from '../styles/theme';

//This is for best UI,when the Language is changing apply the suitable styles adjust UI

const PREFIX = 'component';

const componentNames = {
  ExamsByOperator: `${PREFIX}_ExamsByOperator`,
  ExamAccordion: `${PREFIX}_ExamAccordion`,
  ReviewsAccordion: `${PREFIX}_ReviewsAccordion`,
  AIGrading: `${PREFIX}_AIGrading`,
  LargeButton: `${PREFIX}_LargeButton`,
  AddNewField: `${PREFIX}_AddNewField`,
  SmallButton: `${PREFIX}_SmallButton`,
  PatientExams: `${PREFIX}_PatientExams`,
  SubscriptionSelection: `${PREFIX}_SubscriptionSelection`,
  SubscriptionDetails: `${PREFIX}_SubscriptionDetails`,
  ExamPlansManagement: `${PREFIX}_ExamPlansManagement`,
  PurchaseExams: `${PREFIX}_PurchaseExams`,
  billingHistory: `${PREFIX}_billingHistory`,
  monthlySubscription: `${PREFIX}_monthlySubscription`,
  about: `${PREFIX}_About`,
  header: `${PREFIX}_Header`,
};

const regionBasedDefaultStyles = {
  en: {
    en_SubscriptionSelection: {
      width: '20%',
      xs_1: 3,
      xs_2: 9,
    },
  },
  de: {
    de_SubscriptionSelection: {
      width: '20%',
      xs_1: 3,
      xs_2: 9,
    },
  },
};
export const customLanguagestyles = {
  en: {
    [componentNames.header]: {
      btnWidth_1: 200,
      btnWidth_2: 80,
    },

    [componentNames.about]: {
      size: 'md',
      top: 24,
    },
    [componentNames.monthlySubscription]: {
      customPadding: 2,
      incomplete_expired: false,
    },
    [componentNames.ExamsByOperator]: {
      icon: { width: '4%' },
      date: { width: '20%' },
      patient: { width: '27%', fontWeight: 'bold', textTransform: 'capitalize' },
      practitioner: { width: '27%', fontWeight: 'bold', textTransform: 'capitalize' },
      status: { width: '22%' },
    },
    [componentNames.AIGrading]: {
      containerWidth: 540,
    },
    [componentNames.PatientExams]: {
      icon: {
        width: 1.5,
      },
      label_1: {
        width_md: 5,
        width_lg: 5,
        width_xl: 4.5,
      },
      label_2: {
        width_md: 5.5,
        width_lg: 5,
        width_xl: 6,
      },
      title_1: {
        width_lg: 8,
        width_xl: 9,
      },
      title_2: {
        width_lg: 4,
        width_xl: 3,
      },
      width_1: 3,
      width_2: 9,
    },

    [componentNames.ExamAccordion]: {
      All_Exams: {
        xs: {
          sectionOne: 8,
          sectionTwo: 4,
        },
        xl: {
          sectionOne: 9,
          sectionTwo: 3,
        },
      },
    },
    [componentNames.ReviewsAccordion]: {
      sectionOne: 8.5,
      sectionTwo: 2.5,
    },
    [componentNames.LargeButton]: {
      width: '25ch',
    },
    [componentNames.AddNewField]: {
      lg: {
        input: 9.5,
        button: 2.5,
      },
      xl: {
        input: 9.5,
        button: 2.5,
      },
      fixedButton: '25%',
    },
    [componentNames.SmallButton]: {
      width: '5ch',
    },
    [componentNames.SubscriptionSelection]: {
      [SINGAPORE]: regionBasedDefaultStyles.en.en_SubscriptionSelection,
      [AUSTRALIA]: regionBasedDefaultStyles.en.en_SubscriptionSelection,
      [GERMANY]: regionBasedDefaultStyles.en.en_SubscriptionSelection,
      [IRELAND]: regionBasedDefaultStyles.en.en_SubscriptionSelection,
      [UNITED_KINGDOM]: regionBasedDefaultStyles.en.en_SubscriptionSelection,
      [INDIA]: regionBasedDefaultStyles.en.en_SubscriptionSelection,
    },
    [componentNames.SubscriptionDetails]: {
      ['resumeSubscription']: {
        padding: theme.spacing(1, 3),
        marginInline: theme.spacing(1.5),
      },
    },
    [componentNames.ExamPlansManagement]: {
      height: 430,
    },
    [componentNames.PurchaseExams]: {
      width: '150px',
      dialogBoxSize: {
        oneTimeWidth: '450px',
        recurringWidth: '540px',
      },
      prepaid: {
        marginRight: theme.spacing(1),
        marginLeft: 0,
      },
      subscription: {
        marginRight: 0,
        marginLeft: theme.spacing(1),
      },
    },
    [componentNames.billingHistory]: {
      tbWidthes: [
        // { width: '20%' },
        // { width: '15%' },
        // { width: '20%' },
        // { width: '15%' },
        // { width: '13%' },
        // { width: '12%' },
      ],
    },
  },
  de: {
    [componentNames.header]: {
      btnWidth_1: 300,
      btnWidth_2: 100,
    },

    [componentNames.about]: {
      size: 'lg',
      top: 8,
    },

    [componentNames.monthlySubscription]: {
      customPadding: 1,
      incomplete_expired: true,
    },

    [componentNames.ExamsByOperator]: {
      icon: { width: '4%' },
      date: { width: '20%' },
      patient: { width: '25%', fontWeight: 'bold', textTransform: 'capitalize' },
      practitioner: { width: '25%', fontWeight: 'bold', textTransform: 'capitalize' },
      status: { width: '26%' },
    },
    [componentNames.AIGrading]: {
      containerWidth: 580,
    },
    [componentNames.PatientExams]: {
      icon: {
        width: 1.5,
      },
      label_1: {
        width_md: 4,
        width_lg: 4,
        width_xl: 4.5,
      },
      label_2: {
        width_md: 6.5,
        width_lg: 6.5,
        width_xl: 6,
      },
      title_1: {
        width_lg: 7.6,
        width_xl: 7.8,
      },
      title_2: {
        width_lg: 4.4,
        width_xl: 4.2,
      },
      width_1: 5,
      width_2: 7,
    },

    [componentNames.ExamAccordion]: {
      All_Exams: {
        xs: {
          sectionOne: 7.7,
          sectionTwo: 4.3,
        },
        xl: {
          sectionOne: 7.7,
          sectionTwo: 4.3,
        },
      },
    },
    [componentNames.ReviewsAccordion]: {
      sectionOne: 8.5,
      sectionTwo: 3,
    },
    [componentNames.LargeButton]: {
      width: '30ch',
    },
    [componentNames.AddNewField]: {
      lg: {
        input: 9.5,
        button: 2.5,
      },
      xl: {
        input: 9.5,
        button: 2.5,
      },
      fixedButton: '32%',
    },
    [componentNames.SmallButton]: {
      width: '7ch',
    },
    [componentNames.SubscriptionSelection]: {
      [SINGAPORE]: {
        width: '20%',
        xs_1: 3.5,
        xs_2: 8.5,
      },
      [AUSTRALIA]: regionBasedDefaultStyles.de.de_SubscriptionSelection,
      [GERMANY]: regionBasedDefaultStyles.de.de_SubscriptionSelection,
      [IRELAND]: regionBasedDefaultStyles.de.de_SubscriptionSelection,
      [UNITED_KINGDOM]: regionBasedDefaultStyles.de.de_SubscriptionSelection,
      [INDIA]: regionBasedDefaultStyles.de.de_SubscriptionSelection,
    },
    [componentNames.SubscriptionDetails]: {
      ['resumeSubscription']: {
        padding: theme.spacing(1, 1),
        marginInline: theme.spacing(1.5),
      },
    },
    [componentNames.ExamPlansManagement]: {
      height: 490,
    },
    [componentNames.PurchaseExams]: {
      width: '170px',
      dialogBoxSize: {
        oneTimeWidth: '500px',
        recurringWidth: '650px',
      },
      prepaid: {
        marginRight: theme.spacing(0.5),
        marginLeft: 0,
      },
      subscription: {
        marginRight: 0,
        marginLeft: theme.spacing(0.5),
      },
      discount: {
        [5]: {
          left: '57%',
          width: '95px',
        },
        [10]: {
          left: '70%',
          width: '70px',
        },
        [15]: {
          left: '70%',
          width: '70px',
        },
      },
    },
    [componentNames.billingHistory]: {
      tbWidthes: [
        // { width: '20%' },
        // { width: '15%' },
        // { width: '15%' },
        // { width: '20%' },
        // { width: '15%' },
        // { width: '15%' },
      ],
    },
  },
};
