import React, { useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { usePromiseTracker } from 'react-promise-tracker';

import { styled } from '@mui/material/styles';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';

import AddHealthData from '../AddHealthData';
import HealthDataTable from './HealthDataTable';
import Loading from '../../../components/layout/organisms/Loading/Loading';
import IconHeading from '../../../components/layout/molecules/IconHeading';
import SmallButton from '../../../components/layout/atoms/SmallButton';

import { handleModalOpen } from '../../../actions/controls';
import { ActivityIcon, PlusIcon } from '../../../resources/icons';
import { UPDATE_HEALTH_DATA } from '../../../actions/types';
import { getProfileLayoutByRegion } from '../../../utils/configurationHelpers';

const HealthData = (props) => {
  const { promiseInProgress } = usePromiseTracker();
  const dispatch = useDispatch();
  const staticWords = useSelector((state) => state.handlingTranslation.words);
  const handlePopup = () => {
    props.handleModalOpen('AddHealthData');
    dispatch({
      type: UPDATE_HEALTH_DATA,
      payload: '',
    });
  };
  return (
    <Grid height={getProfileLayoutByRegion().cardHeight}>
      <Grid container alignItems="center" justifyContent="space-between" mb={3}>
        <Grid item>
          <IconHeading title={'Latest Health Data'} icon={<ActivityIcon />} noMargin />
        </Grid>
        <Grid item>
          <SmallButton startIcon={<PlusIcon />} onClick={handlePopup}>
            {staticWords.New_Data}
          </SmallButton>
        </Grid>
      </Grid>
      {promiseInProgress ? (
        <Grid mt={12}>
          <Loading />
        </Grid>
      ) : (
        <div>
          {props.healthData && (
            <HealthDataTable
              tableHeight={getProfileLayoutByRegion().healthDataHeight}
              healthData={Object.entries(props.healthData).reverse()}
              extraHeaderHeight
            />
          )}
        </div>
      )}
      <AddHealthData btnName={staticWords.Submit} />
    </Grid>
  );
};

HealthData.propTypes = {
  healthData: PropTypes.object,
};

const mapStateToProps = (state) => ({
  patientDetails: state.patients.patientDetails,
  healthData: state.healthData.mostRecentHealthData,
});

export default connect(mapStateToProps, {
  handleModalOpen,
})(HealthData);
